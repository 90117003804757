<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { RouterView } from 'vue-router'
  import { RHDHVSnackbar } from '@rhdhv/vue-component-library'
  import AppHeader from '@/components/app/AppHeader.vue'

  const authStore = inject('useAuthStore')
  const authorizationStore = useAuthorizationStore()
  const { user } = storeToRefs(authStore)

  onMounted(async () => {
    if (user.value) authorizationStore.setCurrentItem([user.value.role])
  })
</script>

<template>
  <v-app>
    <AppHeader />
    <v-main>
      <RouterView />
    </v-main>
    <RHDHVSnackbar :excluded-http-methods="['GET', 'OPTIONS']"></RHDHVSnackbar>
  </v-app>
</template>
<style lang="scss">
  // the main file that imports everything related with styles
  @use '/src/scss/settings.scss';
</style>
