<script setup>
  import { RHDHVWorkOrderField, TextField } from '@rhdhv/vue-component-library'

  const props = defineProps({
    store: { type: Object, required: true }
  })

  const isValid = ref(false)
  const form = ref()

  const { currentItem } = storeToRefs(props.store)

  const validateForm = async () => {
    return await form.value.validate()
  }

  defineExpose({
    validateForm
  })
</script>

<template>
  <v-form ref="form" v-model="isValid" validate-on="input" v-if="props.store">
    <div v-for="field in props.store.fieldsFiltered" :key="field">
      <RHDHVWorkOrderField
        v-if="field.parameter === 'work_order'"
        :model-value="currentItem"
        :field="field"
        :store="store"
      />
      <TextField v-else :model-value="currentItem" :field="field" :store="store" />
    </div>
  </v-form>
</template>
