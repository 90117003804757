import './assets/main.css'
import * as Sentry from '@sentry/vue'
import { createApp } from 'vue'
import { pinia } from './modules/pinia.ts'
import vuetify from './plugins/vuetify'
import router from './router'

import { AuthenticationManagement } from '@rhdhv/vue-authentication-middleware'
import { UserManagementApp } from '@rhdhv/vue-user-management'
import { SpeckleApp } from '@rhdhv/vue-speckle'
import { ComponentLibrary, useEventsBus } from '@rhdhv/vue-component-library'
import App from './App.vue'
import 'vuetify/dist/vuetify.min.css'

import HeaderImage from '/header.jpeg'
import HeaderImageLazy from '/header_lazy.jpg'

import apiUrls from '@/api/apiUrls.js'
import { useDjangoAuthStore } from './stores/authDjango'

import { useSnackbarStore } from './stores/snackbarStore'

pinia.use(({ store }) => {
  store.$api = api
  store.$apiUrls = apiUrls
})
const { bus } = useEventsBus()

const { api } = useApi()

const app = createApp(App).use(vuetify).use(pinia).use(router)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  environment: import.meta.env.MODE,
  tracePropagationTargets: ['*'],
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, // Capture 10% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in cloud and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const authStore = useDjangoAuthStore(pinia)

app
  .use(AuthenticationManagement, {
    appTitle: 'GEOProfile',
    headerImage: HeaderImage,
    headerImageLazy: HeaderImageLazy,
    api: api,
    snackbarStore: useSnackbarStore(pinia),
    authStore: authStore,
    apiBaseUrl: import.meta.env.VITE_APP_API_BASE_URL,
    appFrontendBaseUrl: import.meta.env.VITE_APP_FRONTEND_BASE_URL,
    showForgotPassword: false
  })
  .provide('useAuthStore', authStore)

app.use(UserManagementApp, {
  authorizationStore: useAuthorizationStore(),
  authStore: authStore
})

app.use(SpeckleApp, {
  VITE_APP_SPECKLE_ID: import.meta.env.VITE_APP_SPECKLE_ID,
  VITE_APP_SPECKLE_SECRET: import.meta.env.VITE_APP_SPECKLE_SECRET,
  VITE_APP_SERVER_URL: import.meta.env.VITE_APP_SERVER_URL,
  VITE_APP_SPECKLE_NAME: import.meta.env.VITE_APP_SPECKLE_NAME,
  bus: bus
})

const projectStore = useProjectStore(pinia)
app.use(ComponentLibrary, { externalBus: bus, projectStore: projectStore })

app.mount('#app')
